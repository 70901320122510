<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">
                Send message to groups
            </div>
            <div class="main">
                <div v-if="is_media">

                    <div class="image" v-if="is_image">
                        <img :src="image"/>
                    </div>

                    <div v-if="is_video">
                        <video controls id="video-tag" ref="videoTag">
                            <source id="video-source" :src="video">
                            Your browser does not support the video tag.
                        </video>
                    </div>

                </div>
                <div v-else class="btns">
                    <div class="btn" @click="upload()">Add Image</div>
                    <div class="btn" @click="upload_video()">Add Video</div>
                </div>

                <textarea placeholder="Enter your message" v-model="text"></textarea>

                <loader :height="'35px'" v-if="loading"/>
                <div class="btn" v-else @click="send()">Send</div>
            </div>
        </div>
        
        <input type="file" accept="video/*" ref="video_input" @change="handleVideo($event)"/>
        <input type="file" accept="image/*" class="hidden" 
                ref="image" @change="handleImageUpload($event)"/>

    </div>
</template>

<script>

import imageCompression from 'browser-image-compression';

import loader from '../../loader.vue';

export default {
    components: {
        loader
    },
    props: ['city'],
    data() {
        return {
            link: '',
            text: '',
            
            is_media: false,

            image: '',
            img_file: null,
            is_image: '',
            
            video: '',
            video_file: null,
            is_video: '',
            videoTag: null,
            video_input: null,

            loading: false
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },

        
        upload() {
            this.$refs.image.click()
        },
        upload_video() {
            this.$refs.video_input.click()
        },
        
        handleImageUpload(event) {
            for (let i = 0; i < event.target.files.length; i++) {
                let mediaType = event.target.files[i].type
                if (mediaType.includes('image')) {
                    const picture = event.target.files[i]
                    
                    const options = {
                        maxSizeMB: 0.03,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    }
                    imageCompression(picture, options).then( res => {
                        this.img_file = new File([res], picture.name)
                        this.is_image = '1'
                        
                        const reader = new FileReader()
                        reader.readAsDataURL(picture)
                        reader.onload = event => {
                            this.is_media = true
                            this.image = event.target.result

                            this.$refs['image'].value = ''
                        }
                    })

                }
            }
        },
        async handleVideo(event) {
            
            let mediaType = event.target.files[0].type
            if (mediaType.includes('video')) {
                
                this.is_video = '1'
                
                this.video_file = event.target.files[0]
                let size = this.video_file.size / 1000000


                if (size > 30) {
                    this.video_err(); 
                    this.is_video = ''
                    return 
                }
                
                var reader = new FileReader();

                reader.onload = function(e) {
                    this.is_media = true
                    this.video = e.target.result
                    this.videoTag.load()
                }.bind(this)

                reader.readAsDataURL(event.target.files[0]);
            }
        },

        send() {
            if (this.text == '') { this.empty(); return }

            this.loading = true

            let form = new FormData()
            form.append('message', this.text)

            form.append('is_image', this.is_image)
            if (this.is_image == '1') {
                form.append('image', this.img_file)
            }

            form.append('is_video', this.is_video)
            if (this.is_video == '1') {
                form.append('video', this.video_file)
            }

            this.$http.post('/admin/groups/send', form)
            .then(() => {
                this.loading = false
                this.close()
            })
            this.success()

        }
    },
    notifications: {
        video_err: {
            type: 'warn',
            title: 'Too large',
            message: 'Vidoe should not be more than 30 MB'
        },
        empty: {
            'type': 'warn',
            'title': 'Empty message',
            'message': ''
        },
        success: {
            'type': 'success',
            'title': 'Sent',
            'message': 'Message sent, check whatsapp for delivery'
        }
    }
}
</script>

<style scoped>

    .popup {
        z-index: 10;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .box {
        background-color: white;
        width: 500px;
        position: relative;
        z-index: 20;
        border-radius: 5px;
    }
    .title {
        padding: 15px 20px;
        font-weight: 500;
    }
    .main {
        padding: 20px;
        border-top: 1px solid #ddd;

        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .image img {
        width: 50%;
    }
    video {
        width: 50%;
    }
    input, textarea {
        padding: 15px;
        border-radius: 5px;
        border: 1px solid #ddd;
        width: 100%;
        box-sizing: border-box;
        /* margin-bottom: 20px; */
    }
    textarea {
        height: 100px;

    }
    .btns {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .btn {
        padding: 8px;
        border-radius: 5px;
        cursor: pointer;
        background-color: var(--main);
        color: white;
        text-align: center;
        font-weight: 500;
    }

    
    
    input[type=file] {
        visibility: hidden;
        display: none;
    }
</style>